import React from "react";
const AquaLogo: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M4.072 20.3a2.999 2.999 0 0 0 3.856 0 3.002 3.002 0 0 0 .67-3.798l-2.095-3.227a.6.6 0 0 0-1.005 0L3.4 16.502a3.003 3.003 0 0 0 .671 3.798zM16.072 20.3a2.999 2.999 0 0 0 3.856 0 3.002 3.002 0 0 0 .67-3.798l-2.095-3.227a.6.6 0 0 0-1.005 0L15.4 16.502a3.003 3.003 0 0 0 .671 3.798zM10.072 10.3a2.999 2.999 0 0 0 3.856 0 3.002 3.002 0 0 0 .67-3.798l-2.095-3.227a.6.6 0 0 0-1.005 0L9.4 6.502a3.003 3.003 0 0 0 .671 3.798z" />
  </svg>
);
export default AquaLogo;
